<template>
  <div>
    <TagbyFilterV2
      :initialParams="state.filterParams"
      :categoryOptions="categoryOptions"
      :leftWidth="3"
      @search="search"
    >
      <template #buttons>
        <BButton
          variant="outline-secondary"
          class="mr-1"
          :to="{ name: 'message-dm-group-create' }"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-50"
          />
          <span class="align-middle">Add New</span>
        </BButton>
      </template>
    </TagbyFilterV2>
    <TagbyList
      v-model="state.currentPage"
      :items="getters.items"
      :fields="tableColumns"
      :busy="state.isBusy"
      :current-page="state.currentPage"
      :total-rows="state.totalRows"
      :per-page="state.perPage"
      @page-change="changePage"
      @sort-changed="changeSort"
    >
      <template #cell(idx)="data">
        <BLink :to="{ name: 'message-dm-group-detail', params: { idx: data.value} }">
          {{ data.value }}
        </BLink>
      </template>

      <template #cell(state)="data">
        <div
          style="width: 80px"
          :class="data.value === 'ACTIVE' ? 'text-success' : null"
        >
          {{ data.value }}
        </div>
      </template>

      <template #cell(member_info)="data">
        {{ data.item.member_type }}:{{ data.item.member_idx }}:{{ data.item.member_name }}
      </template>

      <template #cell(created_at)="data">
        <TagbyDatetimeColumn :value="data.value" />
      </template>

      <template #cell(available_time)="data">
        {{ data.item.available_start_time }} ~ {{ data.item.available_end_time }}
      </template>

    </TagbyList>
  </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api"
import {
  BLink,
  BButton,
  BAvatarGroup,
  BBadge,
  BAvatar,
} from "bootstrap-vue"
import useInitialize from "./hooks/useInitialize"
import useItems from "./hooks/useItems"
import useState from "./hooks/useState"
import TagbyFilterV2 from "@/components/TagbyFilterV2.vue"
import TagbyList from "@/components/TagbyList.vue"
import TagbyDatetimeColumn from "@/components/TagbyDatetimeColumn.vue"
import TagbySnsAvatarV2 from "@/components/TagbySnsAvatarV2.vue"
import state from "./state"
import getters from "./getters"

export default defineComponent({
  components: {
    BLink,
    BButton,
    BAvatar,
    BAvatarGroup,
    BBadge,
    TagbyFilterV2,
    TagbyList,
    TagbyDatetimeColumn,
    TagbySnsAvatarV2,
  },
  setup() {
    const {
      resetState,
    } = useState()
    resetState()

    const {
      initialize,
    } = useInitialize()
    initialize()

    const {
      categoryOptions,
      hasRequestTime,
      tableColumns,
      search,
      searchList,
      changePage,
      changeSort,
    } = useItems()
    searchList()

    return {
      state,
      getters,
      categoryOptions,
      hasRequestTime,
      tableColumns,
      search,
      searchList,
      changePage,
      changeSort,
    }
  },
})
</script>
