export default () => ({
  currentPage: null,
  perPage: 1,
  totalRows: null,
  groupList: [],
  admList: [],
  isBusy: false,
  filterParams: {},
  sortBy: "-idx",
  isLoading: false,
  errorReasonModal: {
    isVisible: false,
    errorReason: null,
  },
})
