import {
  reactive,
  computed,
} from '@vue/composition-api'

import state from './state'

import {
  indexing,
  grouping,
} from '@/utils/value'

const items = computed(() => state.groupList)

export default reactive({
  items,
})
